import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { AiFillGithub } from "react-icons/ai";
import Tag from "./Tag";

// ToDo: Taka max height af img og fá betri svg file-a

export default function ProjectItem({ project, scrollTop }) {
  const cardRef = useRef(null);
  const [opacity, setOpacity] = useState(1);
  const threshold = 150;
  const bottomThresholdUpper = window.innerHeight - 150;
  const bottomThresholdLower = window.innerHeight - 50;

  useEffect(() => {
    const cardY =
      cardRef.current.getBoundingClientRect().top +
      cardRef.current.clientHeight;
    // If current top position + height is scrolling above screen
    if (cardY < threshold && cardY >= 50) {
      const newOp = (Math.round(cardY) - 50) * 0.01;
      setOpacity(newOp);
    } else if (cardY < 50) {
      setOpacity(0);
    } else if (cardY >= threshold) {
      setOpacity(1);
    }
    // Check if card is approaching the bottom of screen
    if (cardY >= bottomThresholdUpper && cardY <= bottomThresholdLower) {
      const newOp = Math.round(bottomThresholdLower - cardY) * 0.01;
      setOpacity(newOp);
    } else if (cardY > bottomThresholdLower) {
      setOpacity(0);
    }
  }, [scrollTop, bottomThresholdLower, bottomThresholdUpper]);

  return (
    <ProjectContainer ref={cardRef} op={opacity}>
      <ProjectInfo>
        <div>
          <ProjectTitle>
            <ProjectLink
              title={`Visit ${project.name} in a new tab`}
              href={project.url}
              target="_blank"
            >
              {project.name}
            </ProjectLink>
          </ProjectTitle>
          <ProjectDate>{project.date}</ProjectDate>
        </div>
        <div>
          <ProjectDescription>{project.description}</ProjectDescription>
        </div>
        <div>
          <ProjectRepo>
            <AiFillGithub size={24} />
            <RepoLink href={project.repo} target="_blank">
              {project.name} on Github
            </RepoLink>
          </ProjectRepo>
        </div>
        <TagContainer>
          {project.tags.map((item, index) => (
            <Tag key={item + index} name={item} />
          ))}
        </TagContainer>
      </ProjectInfo>
      <ProjectImg title={`Visit ${project.name} in a new tab`}>
        <ProjectLink href={project.url} target="_blank">
          <StyledImg src={project.image} alt={project.name} />
        </ProjectLink>
      </ProjectImg>
    </ProjectContainer>
  );
}

const ProjectContainer = styled.section`
  min-height: 35%;
  display: grid;
  grid-template-columns: 0.5fr 0.5fr;
  border-width: 0 0 2px 0;
  border-style: solid;
  border-image: ${({ op }) =>
    `linear-gradient(90deg, rgba(0, 0, 0, ${op}), #fff) 1;`};
  padding: 1rem 1rem;
  margin-bottom: 1rem;

  @media screen and (max-width: 768px) {
    height: auto;
    min-height: auto;
    grid-template-columns: 1fr;
    grid-template-rows: 0.6fr 0.4fr;
    border-image: linear-gradient(90deg, #fff, #000, #fff) 1;
  }
`;

const ProjectInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.6rem 1rem;
  justify-content: space-between;
  gap: 1rem;

  @media screen and (min-width: 2560px) {
    padding: 15% 5%;
  }
`;

const ProjectTitle = styled.h4`
  display: inline-block;
`;

const ProjectLink = styled.a`
  font-size: 24px;
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
  color: #000;

  &:hover {
    text-decoration: underline;
  }
`;

const ProjectDate = styled.h5`
  font-size: 14px;
  font-weight: normal;
  color: #818181;
  font-style: italic;
`;

const ProjectDescription = styled.p`
  font-size: 14px;
`;

const ProjectRepo = styled.div`
  width: fit-content;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.4rem;
`;

const RepoLink = styled.a`
  text-decoration: none;
  color: #000;
  font-weight: 500;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const TagContainer = styled.div`
  display: flex;
  gap: 0.4rem;
  flex-wrap: wrap;
`;

const ProjectImg = styled.div`
  height: 100%;
  width: 100%;
  padding: 0.6rem 0;
  transition: transform 0.1s linear;
  cursor: pointer;

  &:hover {
    transform: scale(1.015);
  }
`;

const StyledImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
