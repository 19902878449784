import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import About from "./Components/About/About";
import Projects from "./Components/Projects/Projects";
import Nomatch from "./Components/Nomatch";

export default function Routes() {
  return (
    <Switch>
      <Redirect exact from="/" to="/me" />
      <Route path="/me" component={About} />
      <Route path="/projects" component={Projects} />
      <Route path="*" component={Nomatch} />
    </Switch>
  );
}
