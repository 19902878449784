import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

export default function AboutIntro() {
  return (
    <IntroContainer>
      <IntroTitle>Hello there!</IntroTitle>
      <IntroText>
        I like web development and have been studying it hard for a while now.
        Under <Link to="/projects">/projects</Link> you’ll be able to see some
        of my work. Apart from web developing my interests include gaming and
        Esports, going to the gym, music and general freedom.
      </IntroText>
    </IntroContainer>
  );
}

const IntroContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  @media screen and (max-width: 1024px) {
  }

  @media screen and (max-width: 768px) {
    gap: 1rem;
  }

  @media screen and (max-width: 480px) {
  }
`;
const IntroTitle = styled.div`
  font-size: 24px;
  font-weight: 500;
`;
const IntroText = styled.div`
  width: 50%;
  max-width: 720px;
  text-align: center;
  font-size: 14px;
  line-height: 1.6;

  @media screen and (max-width: 1024px) {
  }

  @media screen and (max-width: 768px) {
    width: 80%;
  }

  @media screen and (max-width: 480px) {
  }
`;
