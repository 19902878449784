import React from "react";
import AboutIntro from "./AboutIntro";
import AboutContent from "./AboutContent";
import styled from "styled-components";
import { motion } from "framer-motion";
import containerVariants from "../../data/motionVariants";

export default function About() {
  return (
    <ContentWrapper
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <AboutIntro />
      <AboutContent />
    </ContentWrapper>
  );
}

const ContentWrapper = styled(motion.div)`
  padding: 2rem 0;
  display: grid;
  grid-template-rows: 0.25fr 0.75fr;

  @media screen and (max-width: 1024px) {
  }

  @media screen and (max-width: 768px) {
    gap: 2rem;
  }

  @media screen and (max-width: 480px) {
    padding: 0;
  }
`;
