import React from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { motion, AnimateSharedLayout } from "framer-motion";

export default function NavLinks() {
  const currLoc = useLocation();
  const path = currLoc.pathname.split("/")[1];
  return (
    <AnimateSharedLayout>
      <LinkContainer>
        <LinkItem>
          {path === "me" ? (
            <LinkSlash layoutId="slash">/</LinkSlash>
          ) : (
            <DummySlash></DummySlash>
          )}
          <StyledLink to="/me" $isactive={path === "me" ? true : false}>
            me
          </StyledLink>
        </LinkItem>
        <LinkItem>
          {path === "projects" ? (
            <LinkSlash layoutId="slash">/</LinkSlash>
          ) : (
            <DummySlash></DummySlash>
          )}
          <StyledLink
            to="/projects"
            $isactive={path === "projects" ? true : false}
          >
            projects
          </StyledLink>
        </LinkItem>
      </LinkContainer>
    </AnimateSharedLayout>
  );
}

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.6rem;
`;

const LinkItem = styled.div`
  padding: 0 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 480px) {
    padding: 0.4rem 2rem;
  }
`;

const LinkSlash = styled(motion.div)`
  height: 100%;
  width: 1rem;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
`;

const DummySlash = styled.div`
  height: 100%;
  width: 1rem;
`;

// Check out NavLink instead for styled active item? or just check framer motion
const StyledLink = styled(Link)`
  width: 100%;
  text-decoration: none;
  text-align: center;
  color: #000;
  font-size: 16px;
  font-weight: ${({ $isactive }) => ($isactive ? "bold" : "normal")};
  /* border: ${({ $isactive }) => ($isactive ? "dashed 2px #000" : "none")}; */
  border-radius: 12px;
  /* background-color: gray; */
  @media screen and (max-width: 480px) {
    /* padding: 0.4rem 2rem; */
  }
`;
