import React, { useState } from "react";
import styled from "styled-components";
import { projects } from "../../data/projects";
import ProjectItem from "./ProjectItem";
import { motion } from "framer-motion";
import containerVariants from "../../data/motionVariants";

export default function Projects() {
  const [currentScroll, setScroll] = useState(0);

  const handleScroll = (ev) => {
    setScroll(ev.target.scrollTop);
  };

  return (
    <ProjectsContainer
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
      onScroll={(ev) => handleScroll(ev)}
    >
      {projects.map((item, index) => (
        <ProjectItem key={index} project={item} scrollTop={currentScroll} />
      ))}
    </ProjectsContainer>
  );
}

const ProjectsContainer = styled(motion.div)`
  overflow: auto;
  /* padding: 2rem; */

  @media screen and (max-width: 768px) {
    overflow: visible;
  }
`;
