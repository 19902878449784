import React from "react";
import styled from "styled-components";
import Navbar from "../Components/Nav/Navbar";

export default function Layout({ children }) {
  return (
    <LayoutContainer>
      <Navbar />
      {children}
    </LayoutContainer>
  );
}

const LayoutContainer = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 0.25fr 0.75fr;
  @media screen and (max-width: 1024px) {
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }

  @media screen and (max-width: 480px) {
    height: auto;
    width: auto;
    grid-template-rows: 0.25fr 0.75fr;
    gap: 2rem;
  }
`;
