import React from "react";
import styled from "styled-components";
import Self from "../../images/self2.png";
import ContactInfo from "../About/ContactInfo";
import NavLinks from "./NavLinks";

export default function Navbar() {
  return (
    <NavContainer>
      <Portrait>
        <SelfImg src={Self} alt="Selfie" />
      </Portrait>
      <NavName>Jóhann Þorvaldsson</NavName>
      <ContactInfo />
      <NavLinks />
    </NavContainer>
  );
}

const NavContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 20% 1rem 0 1rem;
  border-width: 0 2px 0 0;
  border-style: solid;
  border-image: linear-gradient(#fff, #000, #000, #fff) 1 100%;

  @media screen and (max-width: 480px) {
    padding: 1rem 0.6rem 0 0.6rem;
  }

  @media screen and (max-width: 768px) {
    border: none;
  }

  @media screen and (min-width: 2560px) {
    padding: 50% 1rem 0 1rem;
  }
`;

const Portrait = styled.div`
  height: 128px;
  width: 128px;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 0 12px 12px rgba(0, 0, 0, 0.2);
`;

const SelfImg = styled.img`
  width: 100%;
`;

const NavName = styled.div`
  font-size: 24px;
  text-align: center;
`;
