import React from "react";
import styled from "styled-components";

export default function ContactInfo() {
  return (
    <InfoWrapper>
      {/* MailTo? */}
      <InfoItem href="mailto:johannth88@gmail.com">
        johannth88@gmail.com
      </InfoItem>
      <InfoItem href="https://www.github.com/johannTor">
        github.com/johannTor
      </InfoItem>
      {/* <InfoItem href="#">linkedin.com/johannTor</InfoItem> */}
    </InfoWrapper>
  );
}

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.4rem;
`;

const InfoItem = styled.a`
  display: block;
  text-decoration: none;
  color: #000;
  font-size: 14px;

  &:hover {
    text-decoration: underline;
  }
`;
