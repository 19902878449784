import React from "react";
import styled from "styled-components";

export default function AssetCollection() {
  return (
    <AssetContainer>
      <AssetRow>
        <AssetSection>
          <AssetCategory>Languages</AssetCategory>
          <AssetList>
            <ListItem>HTML, CSS & JS</ListItem>
            <ListItem>C / C++ / C#</ListItem>
            <ListItem>Java</ListItem>
            <ListItem>Python</ListItem>
            <ListItem>SQL</ListItem>
          </AssetList>
        </AssetSection>
        <AssetSection>
          <AssetCategory>The Frontend</AssetCategory>
          <AssetList>
            <ListItem>React</ListItem>
            <ListItem>Next.js</ListItem>
            <ListItem>Gatsby</ListItem>
            <ListItem>Angular</ListItem>
          </AssetList>
        </AssetSection>
        <AssetSection>
          <AssetCategory>The Backend</AssetCategory>
          <AssetList>
            <ListItem>Node.js</ListItem>
            <ListItem>Express</ListItem>
            <ListItem>MongoDB</ListItem>
            <ListItem>Socket.IO</ListItem>
            <ListItem>GraphQL</ListItem>
          </AssetList>
        </AssetSection>
      </AssetRow>
      <AssetRow rowcount={"one"}>
        <AssetSection>
          <AssetCategory>Other</AssetCategory>
          <AssetList>
            <ListItem>Heroku/Netlify/Vercel</ListItem>
            <ListItem>CI with CircleCI</ListItem>
            <ListItem>Design with Figma</ListItem>
            <ListItem>REST APIs</ListItem>
            <ListItem>React Native</ListItem>
            <ListItem>Git</ListItem>
          </AssetList>
        </AssetSection>
      </AssetRow>
    </AssetContainer>
  );
}

const AssetContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  height: 100%;
  width: 100%;
  padding: 5% 10%;
  @media screen and (min-width: 2560px) {
    padding: 10% 10%;
  }

  @media screen and (max-width: 480px) {
    height: auto;
  }
`;

const AssetRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  @media screen and (max-width: 1024px) {
  }

  @media screen and (max-width: 768px) {
  }

  @media screen and (max-width: 480px) {
    grid-template-columns: 1fr;
    grid-template-rows: ${({ rowcount }) =>
      rowcount === "one" ? "1fr" : "1fr 1fr 1fr"};
    gap: 1rem;
    padding: 1rem 0;
  }
`;

const AssetSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.4rem;
`;

const AssetCategory = styled.h4`
  font-size: 16px;
  font-weight: 500;
`;

const AssetList = styled.ul`
  /* padding-left: 2rem; */

  @media screen and (max-width: 480px) {
    list-style-type: none;
  }
`;

const ListItem = styled.li`
  padding: 0;
  @media screen and (max-width: 480px) {
    text-align: center;
  }
`;
