import React from "react";
import styled from "styled-components";
import AssetCollection from "./AssetCollection";

export default function AboutContent() {
  return (
    <ContentContainer>
      <ContentHeader>
        <LeftLine />
        <TextContainer>
          <HeaderText>Main Assets</HeaderText>
        </TextContainer>
        <RightLine />
      </ContentHeader>
      <AssetCollection />
    </ContentContainer>
  );
}

const ContentContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  @media screen and (max-width: 768px) {
    gap: 2.4rem;
  }

  @media screen and (max-width: 480px) {
    gap: 2.4rem;
  }
`;

const ContentHeader = styled.div`
  display: flex;
  width: 100%;
`;

const LeftLine = styled.div`
  flex: 1;
  border-width: 0 0 2px 0;
  border-style: solid;
  border-image: linear-gradient(90deg, #000, #fff) 1;
`;
const TextContainer = styled.div`
  position: relative;
  min-height: 2rem;
  flex: 0.4;
`;

const HeaderText = styled.h4`
  position: absolute;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  top: 0.8rem;
  width: 100%;
`;
const RightLine = styled.div`
  flex: 1;
  border-width: 0 0 2px 0;
  border-style: solid;
  border-image: linear-gradient(90deg, #fff, #000) 1;
`;
