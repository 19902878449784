import React from "react";

export default function Nomatch() {
  return (
    <div>
      <h1>Page not found</h1>
      <div>Todo: make a 404 page :)</div>
    </div>
  );
}
