import React from "react";
import styled from "styled-components";
// import { techColors } from "../data/techColors";

export default function Tag({ name }) {
  return <TagContainer>{name}</TagContainer>;
}

const TagContainer = styled.div`
  padding: 0.2rem 0.6rem;
  border-radius: 12px;
  border: 2px solid #000;
  cursor: default;
`;
