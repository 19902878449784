import "./App.css";
import styled from "styled-components";
import Layout from "./Layout/Layout";
import Routes from "./Routes";

function App() {
  return (
    <AppWrapper>
      <Layout>
        <Routes />
      </Layout>
    </AppWrapper>
  );
}

const AppWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;

  @media screen and (max-width: 480px) {
    width: auto;
    height: auto;
  }
`;

export default App;
