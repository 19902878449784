import InstaQuiz from "../images/instaquiz.svg";
import IceTruck from "../images/icetruck.svg";
import TheCrypt from "../images/thecrypt.svg";
import HeilsanMin from "../images/heilsan.svg";

export const projects = [
  {
    name: "InstaQuiz",
    date: "March 2021",
    description:
      "A page where you can quickly create a quiz and share it with other people.",
    tags: ["html", "css", "js", "react", "node", "express", "socketio"],
    image: InstaQuiz,
    url: "https://insta-quiz.netlify.app/",
    repo: "https://github.com/johannTor/realtime-quiz-app",
  },
  {
    name: "Food Trucks",
    date: "February 2021",
    description:
      "An app for logging and displaying various food trucks around Iceland.",
    tags: ["html", "css", "js", "nextjs", "node", "express", "mongodb"],
    image: IceTruck,
    url: "https://ice-trucks.herokuapp.com/",
    repo: "https://github.com/johannTor/IceTrucks",
  },
  // {
  //   name: "EfniCMS/API",
  //   date: "January-February 2021",
  //   description:
  //     "Efni API is a RESTful API which delivers product data for e-commerce frontends.",
  //   tags: ["html", "css", "js", "react", "node", "express"],
  //   url: "https://github.com/johannTor/efni_api",
  //   repo: "https://github.com/johannTor/efni_api",
  // },
  {
    name: "Crypt of Currency",
    date: "November 2020",
    description:
      "A place where cryptocurrency enthusiasts can get recent crypto related news, see info on top 10 currencies and chat with other users",
    tags: ["html", "css", "js", "react", "node", "express", "socketio"],
    image: TheCrypt,
    url: "https://thecrypt.netlify.app/",
    repo: "https://github.com/johannTor/crypt",
  },
  {
    name: "Heilsan Mín",
    date: "Oktobober 2020",
    description:
      "Heilsan Mín enables its user to keep track of symptoms and log them with date, description and other information.",
    tags: ["html", "css", "js"],
    image: HeilsanMin,
    url: "http://heilsanmin.surge.sh/",
    repo: "https://github.com/johannTor/health-app",
  },
];
